
import ItemComponent from './swiperItem'
import SwiperComponent from './swiper'

const swiper = SwiperComponent
const swiperItem = ItemComponent
const install = function (Vue, globalOptions) {
  if (globalOptions) {
    SwiperComponent.props.globalOptions.default = () => globalOptions
  }
  Vue.component(SwiperComponent.name, SwiperComponent)
  Vue.component(ItemComponent.name, ItemComponent)
}

const VueAwesomeSwiper = { swiper, swiperItem, install }

//export default VueAwesomeSwiper
export { swiper, swiperItem, install }
