<template>
    <div class="home">
        <swiper v-if="device == 'pc'" :options="swiperOption" class="swiperBlock flexItem110">
            <swiper-item class="flexColStartRowCenter" v-for="(item, index) in pcBanner" :key="index">
                <img @click="goToDiet(index)" :src="item.img" />
            </swiper-item>
        </swiper>
        <swiper v-else :options="swiperOption" class="swiperBlock flexItem110">
            <swiper-item class="flexColStartRowCenter" v-for="(item, index) in mobileBanner" :key="index">
                <img @click="goToDiet(index)" :src="item.img" />
            </swiper-item>
        </swiper>
        <div class="journal-box">
            <div class="title-banner">PUBLICATIONS</div>
            <journal-swiper v-if="device == 'pc'" :options="pcJournalSwiperOption" class="swiperBlock flexItem110">
                <journalSwiper-item class="flexColStartRowCenter" v-for="(item, index) in pcJournalBanner" :key="index">
                    <!-- <div class="journal-image-bg" :style="{backgroundImage: 'url(' + item.bgImg + ')'}"> -->
                        <img  class="journal-image" :src="item.img" @click="bannerPcClick(item,index)"/>
                    <!-- </div> -->
                </journalSwiper-item>
            </journal-swiper>
            <journal-swiper v-else :options="mobileJournalSwiperOption" class="swiperBlock flexItem110">
                <journalSwiper-item class="flexColStartRowCenter" v-for="(item, index) in mobileJournalBanner" :key="index" >
                    <img class="journal-image" :src="item.img" @click="bannerMobileClick(item,index)"/>
                </journalSwiper-item>
            </journal-swiper>
            <div class="more-btn" @click="goToArticleList">
                <img src="../../assets/image/pc/journalBanner/battle——more.png" alt="">
            </div>
        </div>
        <image-box :src="device == 'pc' ? pcHome2 : mobileHome2" :height="device == 'pc' ? '1200px' : '4rem'">
        </image-box>
        <image-box v-if="device == 'mobile'" :src="mobileHome3" :height="'4rem'">
        </image-box>
        <image-box :src="device == 'pc' ? pcHome3 : mobileHome4" :height="device == 'pc' ? '780px' : '6rem'">
        </image-box>
        <image-box :src="device == 'pc' ? pcHome4 : mobileHome5" :height="device == 'pc' ? '800px' : '6rem'">
        </image-box> 
        <image-box :src="device == 'pc' ? pcHome5 : mobileHome6" :height="device == 'pc' ? '800px' : '6rem'">
        </image-box>
    </div>
</template>

<script>
import { swiper, swiperItem } from "../../components/swiper/index";
import { journalSwiper, journalSwiperItem } from "../../components/journalSwiper/index";
import ImageBox from "../../components/ImageBox/ImageBox";
import { windowResize, getDevice } from "../../libs/base";


export default {
    name: "home",
    components: {
        swiper: swiper,
        "swiper-item": swiperItem,
        "image-box": ImageBox,
        "journal-swiper": journalSwiper,
        "journalSwiper-item": journalSwiperItem
    },
    data() {
        return {
            swiperOption: {
                pageBtn: true,
                height: "auto",
                width: "100%",
                callback: () => { },
                pagination: {
                    show: true,
                    canClick: true,
                },
                current: 0,
                itemsNum: 3,
                loop: true,
                loopTime: 5000,
                auto: true,
            },
            pcJournalSwiperOption: {
                pageBtn: false,
                height: "auto",
                width: "100%",
                callback: () => { },
                pagination: {
                    show: true,
                    canClick: true,
                },
                current: 0,
                itemsNum: 6,
                loop: true,
                loopTime: 5000,
                auto: true,
            },
            mobileJournalSwiperOption: {
                pageBtn: true,
                height: "auto",
                width: "100%",
                callback: () => { },
                pagination: {
                    show: true,
                    canClick: true,
                },
                current: 0,
                itemsNum: 6,
                loop: true,
                loopTime: 5000,
                auto: true,
            },
            pcBanner: [
                {
                    img: require("../../assets/image/pc/banner/banner1.jpg"),
                    type: "blank",
                },
                {
                    img: require("../../assets/image/pc/banner/banner2.jpg"),
                    type: "blank",
                },
                {
                    img: require("../../assets/image/pc/banner/banner3.jpg"),
                    type: "blank",
                },
            ],
            pcJournalBanner: [
                {
                    img: require("../../assets/image/pc/journalBanner/001.png"),
                    url:'https://thorax.bmj.com/content/77/7/697',
                    type: "blank",
                    bgImg: require("../../assets/image/pc/journalBanner/01.png"),
                },
                {
                    img: require("../../assets/image/pc/journalBanner/002.png"),
                    url:'https://mhealth.jmir.org/2021/2/e23338',
                    type: "blank",
                    bgImg: require("../../assets/image/pc/journalBanner/02.png"),
                },
                {
                    img: require("../../assets/image/pc/journalBanner/003.png"),
                    url:'https://formative.jmir.org/2021/12/e28040',
                    type: "blank",
                    bgImg: require("../../assets/image/pc/journalBanner/03.png"),
                },
                {
                    img: require("../../assets/image/pc/journalBanner/004.png"),
                    url:'https://journals.sagepub.com/doi/full/10.1177/02692155211032372',
                    type: "blank",
                    bgImg: require("../../assets/image/pc/journalBanner/04.png"),
                },
                {
                    img: require("../../assets/image/pc/journalBanner/005.png"),
                    url:'https://www.frontiersin.org/articles/10.3389/fnut.2022.965801/full',
                    type: "blank",
                    bgImg: require("../../assets/image/pc/journalBanner/05.png"),
                },
                {
                    img: require("../../assets/image/pc/journalBanner/006.png"),
                    url:'https://formative.jmir.org/2022/10/e40316',
                    type: "blank",
                    bgImg: require("../../assets/image/pc/journalBanner/06.png"),
                },
            ],
            mobileBanner: [
                {
                    img: require("../../assets/image/mobile/banner/banner1.jpg"),
                    type: "blank",
                },
                {
                    img: require("../../assets/image/mobile/banner/banner2.jpg"),
                    type: "blank",
                },
                {
                    img: require("../../assets/image/mobile/banner/banner3.jpg"),
                    type: "blank",
                },
            ],
            mobileJournalBanner: [
                {
                    img: require("../../assets/image/mobile/journalBanner/001.png"),
                    url:'https://thorax.bmj.com/content/77/7/697',
                    type: "blank",
                },
                {
                    img: require("../../assets/image/mobile/journalBanner/002.png"),
                    url:'https://mhealth.jmir.org/2021/2/e23338',
                    type: "blank",
                },
                {
                    img: require("../../assets/image/mobile/journalBanner/003.png"),
                    url:'https://formative.jmir.org/2021/12/e28040',
                    type: "blank",
                },
                {
                    img: require("../../assets/image/mobile/journalBanner/004.png"),
                    url:'https://journals.sagepub.com/doi/full/10.1177/02692155211032372',
                    type: "blank",
                },
                {
                    img: require("../../assets/image/mobile/journalBanner/005.png"),
                    url:'https://www.frontiersin.org/articles/10.3389/fnut.2022.965801/full',
                    type: "blank",
                },
                {
                    img: require("../../assets/image/mobile/journalBanner/006.png"),
                    url:'https://formative.jmir.org/2022/10/e40316',
                    type: "blank",
                },
            ],
            device: null,
            pcHome2: require('../../assets/image/pc/home02.png'),
            pcHome3: require('../../assets/image/pc/home03.jpg'),
            pcHome4: require('../../assets/image/pc/home04.jpg'),
            pcHome5: require('../../assets/image/pc/home05.jpg'),
            mobileHome2: require('../../assets/image/mobile/home02.jpg'),
            mobileHome3: require('../../assets/image/mobile/home03.jpg'),
            mobileHome4: require('../../assets/image/mobile/home04.jpg'),
            mobileHome5: require('../../assets/image/mobile/home05.jpg'),
            mobileHome6: require('../../assets/image/mobile/home06.jpg'),
        };
    },
    created() {
        this.device = getDevice();
        console.log(this.device);
    },
    methods: {
        bannerPcClick(item,i){
            window.open(item.url, "_blank")
        },
        bannerMobileClick(item,i){
            window.open(item.url, "_blank")
        },
        goToArticleList(){
            this.$router.push('/articleList')
        },
        goToDiet(index){
            if(index==1){
                this.$router.push('/diet')
            }
            
        }
    },
};
</script>

<style >
.swiper-pagination-bullet-active {
    background: white;
}
</style>

<style scoped lang="scss">
@media screen and (min-width: $minDeviceWidth_pc) {
    @import "home_pc";
}

@media screen and (max-width: $minDeviceWidth_pc) {
    @import "home_mobile";
}
</style>
