
import ItemComponent from './swiperItem'
import SwiperComponent from './swiper'

const journalSwiper = SwiperComponent
const journalSwiperItem = ItemComponent
const install = function (Vue, globalOptions) {
  if (globalOptions) {
    SwiperComponent.props.globalOptions.default = () => globalOptions
  }
  Vue.component(SwiperComponent.name, SwiperComponent)
  Vue.component(ItemComponent.name, ItemComponent)
}

const VueAwesomeSwiper = { journalSwiper, journalSwiperItem, install }

//export default VueAwesomeSwiper
export { journalSwiper, journalSwiperItem, install }
